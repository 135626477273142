"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bulmaInit = void 0;
require("./bulma.scss");
// helps deal with the hamburger menu in Bulma
var supportNavBurgers = function () {
    document.querySelectorAll('.navbar-burger').forEach(function (el) {
        if (!(el instanceof HTMLAnchorElement)) {
            console.log("Not an <a>: ".concat(el.nodeName));
            return;
        }
        el.addEventListener('click', function () {
            var targetId = el.dataset['target'];
            if (!targetId) {
                console.log("No dataset-target found on ".concat(String(el)));
                return;
            }
            el.classList.toggle('is-active');
            var menu = document.getElementById(targetId);
            if (!menu) {
                console.log("No target found with ID ".concat(targetId));
                return;
            }
            menu.classList.toggle('is-active');
        });
    });
};
var supportDeleteButtons = function () {
    document.querySelectorAll('.notification > .delete').forEach(function (deleteButton) {
        var notificationElement = deleteButton.parentNode;
        deleteButton.addEventListener('click', function () {
            var notificationParent = notificationElement === null || notificationElement === void 0 ? void 0 : notificationElement.parentNode;
            if (notificationParent) {
                notificationParent.removeChild(notificationElement);
                if (notificationParent.children.length === 0) {
                    var grandparent = notificationParent.parentNode;
                    if (grandparent) {
                        grandparent.removeChild(notificationParent);
                    }
                }
            }
        });
    });
};
var addBulmaSupport = function () {
    supportNavBurgers();
    supportDeleteButtons();
};
var bulmaInit = function (f) {
    if (document.readyState !== 'loading') {
        addBulmaSupport();
        if (f) {
            f();
        }
    }
    else {
        document.addEventListener('DOMContentLoaded', addBulmaSupport);
        if (f) {
            f();
        }
    }
};
exports.bulmaInit = bulmaInit;
